<template>
  <div class="orm-scrollcontainer orm-h100">
    <div
      class="orm-scrollcontainer__scroll"
      ref="scrollbox"
      :style="{ top: top }"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Scrollcontainer",
  data() {
    return {
      top: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      const el = this.$el;
      const scroll = this.$refs.scrollbox;
      if (
        el.getBoundingClientRect().top < 0 &&
        -el.getBoundingClientRect().top <
          el.clientHeight - scroll.clientHeight - 24
      ) {
        this.top = -el.getBoundingClientRect().top + "px";
      }
    },
  },
};
</script>
