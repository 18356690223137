const env = process.env;
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Station from "../views/Station.vue";
import Vehicles from "../views/Vehicles.vue";
import Reservation from "../views/Reservation.vue";
import Step1 from "../views/Reservation/Step1.vue";
import Step2 from "../views/Reservation/Step2.vue";
import Step3 from "../views/Reservation/Step3.vue";
import Step4 from "../views/Reservation/Step4.vue";
import Final from "../views/Reservation/Final.vue";
import PageNotFound from "../views/PageNotFound.vue";

import Formstyles from "../views/Formstyles.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: env.VUE_APP_STATIONS_PATH+":name",
    name: "station",
    component: Station,
  },
  
  {
    path: env.VUE_APP_VEHICLES_PATH,
    name: "fahrzeuge",
    component: Vehicles,
  },
  {
    path: env.VUE_APP_VEHICLES_PATH+ ":name",
    name: "fahrzeug",
    component: Vehicles,
  },
  {
    path: env.VUE_APP_RESERVATION_PATH,
    name: "Reservation",
    component: Reservation,
  },
  {
    path: env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP1,
    name: "Reservation_Step1",
    component: Step1,
  },
  {
    path: env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP1 +"/:name",
    name: "Reservation_Step1_Selected",
    component: Step1,
  },
  {
    path: env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP2,
    name: "Reservation_Step2",
    component: Step2,
  },
  {
    path: env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP3,
    name: "Reservation_Step3",
    component: Step3,
  },
  {
    path: env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP4,
    name: "Reservation_Step4",
    component: Step4,
  },
  {
    path: env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_FINAL,
    name: "Reservation_Final",
    component: Final,
  },
  {
    path: "/formstyles",
    name: "Formstyles",
    component: Formstyles,
  },
  { path: "/:pathMatch(.*)*", name: "PageNotFound", component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
