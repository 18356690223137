<template>
  <teleport to="body">
    <div class="orm-html orm-modal">
      <div class="orm-container">
        <div class="orm-row center-xs middle-xs orm-h100vh">
          <div class="orm-col-xs-12 orm-col-sm-8">
            <div class="orm-modal__inner orm-py-30 orm-px-30">
              <div class="orm-row">
                <div class="orm-col-xs-12">
                  <h2>{{ data.header }}</h2>
                </div>
                <div class="orm-col-xs-12 orm-mb-16">
                  {{ data.content }}
                </div>
                <div class="orm-col-xs-12">
                  <button
                    class="orm-button__cta outline small"
                    @click="submit(data.buttonFirst)"
                  >
                    {{ data.buttonFirstLabel }}
                  </button>
                  <button
                    class="orm-button__cta outline small"
                    @click="secondSubmit(data.buttonSecond)"
                    v-if="data.buttonSecond"
                  >
                    {{ data.buttonSecondLabel }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
// import routes from "@/router/index";
import store from "@/store";

export default {
  name: "modal",
  props: {
    data: {
      type: Object,
      required: true,
    },
    loadTimes: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetSession: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    submit(route) {
      if (this.loadTimes) store.dispatch("stations/SET_OPENINGTIMES");
      if (this.resetSession) store.dispatch("reservation/DELETE_TARIF");

      if (process.env.VUE_APP_STANDALONE == "true") {
        this.$router.push({name: route});
      } else {
        window.location.href = "/";
      }
    },
    secondSubmit(route) {
      if (this.loadTimes) store.dispatch("stations/SET_OPENINGTIMES");
      if (this.resetSession) store.dispatch("reservation/DELETE_TARIF");

      if (process.env.VUE_APP_STANDALONE == "true") {
        this.$router.push(route);
      } else {
        window.location.href = "/";
      }
    },
  },
};
</script>
