<template
  ><teleport v-if="widget" to="#crentHeader">
    <div class="orm-container-fluid orm-reservationHeader">
      <div class="orm-container">
        <div class="orm-row top-xs middle-sm">
          <div class="orm-col-xs-8 orm-col-md-3 first-xs">
            <div class="orm-reservationHeader__logo">
              <router-link
                v-if="env.VUE_APP_STANDALONE == 'true'"
                :to="{ name: 'Home' }"
                title="Home"
              >
                <div
                  class="orm-reservationHeader__logo_image"
                  :style="{
                    backgroundImage:
                      'url(' +
                      store.getters['general/GET_CLIENT_DATA'][
                        'reservationheader-logo'
                      ] +
                      ')',
                    height: logoheight + 'px',
                  }"
                ></div>
              </router-link>
              <a v-else href="/"
                ><div
                  class="orm-reservationHeader__logo_image"
                  :style="{
                    backgroundImage:
                      'url(' +
                      store.getters['general/GET_CLIENT_DATA'][
                        'reservationheader-logo'
                      ] +
                      ')',
                    height: logoheight + 'px',
                  }"
                ></div
              ></a>
            </div>
          </div>
          <div class="orm-col-xs-12 orm-col-md-7 orm-pb-16 orm-pb-md-0">
            <div class="orm-reservationHeader__indicator orm-flexcontainer">
              <div
                class="orm-indicator orm-indicator__step1"
                :class="{ active: step == 1, checked: step >= 2 }"
              >
                <router-link
                  v-if="step >= 2"
                  :to="{ name: 'Reservation_Step1' }"
                >
                  <p v-html="i18n('step1/title')"></p
                ></router-link>
                <p v-else v-html="i18n('step1/title')"></p>
              </div>
              <div
                class="orm-indicator orm-indicator__step2"
                :class="{ active: step == 2, checked: step >= 3 }"
              >
                <router-link
                  v-if="step >= 3"
                  :to="{ name: 'Reservation_Step2' }"
                >
                  <p v-html="i18n('step2/title')"></p
                ></router-link>
                <p v-else v-html="i18n('step2/title')"></p>
              </div>
              <div
                class="orm-indicator orm-indicator__step3"
                :class="{ active: step == 3, checked: step >= 4 }"
              >
                <router-link
                  v-if="step >= 4"
                  :to="{ name: 'Reservation_Step3' }"
                >
                  <p v-html="i18n('step3/title')"></p
                ></router-link>
                <p v-else v-html="i18n('step3/title')"></p>
              </div>
            </div>
          </div>
          <div
            v-if="store.getters['ORMgeneral/GET_MODULE']('ORM_LOGIN') == 'J'"
            class="orm-col-xs-4 orm-col-md-2 first-xs last-md text-right middle-self-xs"
          >
            <div class="orm-reservationHeader__login">
              {{ i18n("general/login") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  name: "reservationheader",
  props: {
    step: Number,
  },
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentHeader");
    const logoheight = process.env.VUE_APP_CUSTOMER_LOGO_HEIGHT_RESERVATION;
    const env = process.env;

    if (myEle !== null) {
      widget = true;
    }

    return {
      store,
      widget,
      logoheight,
      env,
    };
  },
});
</script>
