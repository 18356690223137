
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  name: "reservationheader",
  props: {
    step: Number,
  },
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentHeader");
    const logoheight = process.env.VUE_APP_CUSTOMER_LOGO_HEIGHT_RESERVATION;
    const env = process.env;

    if (myEle !== null) {
      widget = true;
    }

    return {
      store,
      widget,
      logoheight,
      env,
    };
  },
});
