<template>
  <teleport v-if="widget" to="#crentLocationMap">
    <div
      class="orm-locationMap orm-pb-60 orm-container-fluid orm-background__image"
      :style="{
        backgroundImage:
          'url(' +
          store.getters['general/GET_CLIENT_DATA']['background-locationmap'] +
          ')',
      }"
    >
      <div class="orm-container">
        <div class="orm-row center-xs">
          <div class="orm-col-xs-12 orm-col-sm-11 orm-px-0">
            <h2 class="orm-locationMap__title">
              {{ i18n("modules/locationmap/title") }}
            </h2>
            <div class="orm-row no-gutter">
              <div
                style="position:relative"
                class="orm-col-xs-12 orm-col-md-7 orm-col-lg-9 orm-locationMap__mapContainer orm-MapModule"
              >
                <div style="position:absolute;top:0;right:0">
                  <div id="v-range-selector-map"></div>
                </div>
                <gMap
                  :mapConfig="mapConfig"
                  :apiKey="apiKey"
                  v-slot="{ google, map, bounds }"
                >
                  <div
                    v-for="marker in stations.stationlist"
                    :key="marker.station"
                  >
                    <gMapMarker
                      v-if="marker.latitude != 0 && marker.latitude != null"
                      :marker="marker"
                      :google="google"
                      :map="map"
                      :bounds="bounds"
                      @marker-click="infoWindow"
                    />
                  </div>
                  <gMapUser :google="google" :map="map" :bounds="bounds" />
                </gMap>
              </div>
              <div class="orm-col-xs-12 orm-col-md-5 orm-col-lg-3 ">
                <stationlist />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { mapSettings } from "@/constants/mapSettings";
import { getDistanceFromLatLonInKm } from "@/constants/formater";

import { defineComponent, watchEffect } from "vue";

import gMap from "@/components/maps/gMap.vue";
import gMapMarker from "@/components/maps/gMapMarker.vue";
import gMapUser from "@/components/maps/gMapUser.vue";

import stationlist from "@/components/stationlist.vue";

import store from "@/store";

export default defineComponent({
  name: "LocationMap",
  components: { gMap, gMapMarker, gMapUser, stationlist },
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentLocationMap");
    if (myEle !== null) {
      widget = true;
    }
    const stations = store.state.stations;
    // let selectedStation = store.getters["userSession/GET_STATION"];
    let selectedStation = "";
    const userSession = store.state.userSession.LocalSession;

    watchEffect(() => {
      selectedStation = store.getters["userSession/GET_STATION"];
      // console.log("change", selectedStation);
      // if (selectedStation.station != undefined)
      //   store.dispatch(
      //     "cargroup/GET_CARGROUP_FROM_STATION",
      //     selectedStation.station
      //   );
    });

    const mapConfig = {
      ...mapSettings,
      center: { lat: 52.29240999999999, lng: 8.920219999999972 },
    };

    return {
      widget,
      stations,
      selectedStation,
      getDistanceFromLatLonInKm,
      userSession,
      mapConfig,
      store,
    };
  },

  data() {
    return {
      merchantId: process.env.VUE_APP_CONCARDIS_MERCHANT_ID,
      apiKey: process.env.VUE_APP_MAPS_API_KEY,
    };
  },
  methods: {
    infoWindow() {
      // console.log("Click: ", ev);
    },
  },
});
</script>
