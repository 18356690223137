import apiCall from "../../api/crentAPI";
import store from "@/store";
const ENV = process.env.NODE_ENV === "production" ? "ORM_" : "";
import { formateDatetime, formatHour } from "@/constants/formater";

// import slugify from "slugify";

const state = () => ({
  cargroup: [],
  groupimages: {},
  selectedStationGroupfilter: [],
  globalGroupfilter: [],
});
const getters = {
  GET_GLOBAL_CARGROUPS: (state) => state.cargroup || null,
  GET_GLOBAL_CARGROUP: (state) => state.cargroup || null,
  GET_CARGROUP_BY_ID: (state) => async (carID) => {
    const group =
      state.selectedStationCargroup ||
      JSON.parse(localStorage.getItem(ENV + "selectedStationCargroup")) ||
      (await store.dispatch("cargroup/GET_CARGROUP_FROM_STATION"));
    return group.find((car) => car.id === carID);
  },
  GET_GLOBAL_CARGROUPS_IMAGES: (state) =>
    JSON.parse(JSON.stringify(state.groupimages)) || null,
  GET_GLOBAL_CARGROUPS_IMAGE: (state) => (carID) =>
    state.groupimages[carID] ||
    store.dispatch("cargroup/SET_CARGROUP_IMAGE", carID),
  GET_GROUPFILTER: (state) =>
    JSON.parse(JSON.stringify(state.selectedStationGroupfilter)) || null,
  GET_GLOBAL_GROUPFILTER: (state) => state.globalGroupfilter,
  // return  || null;
};
const actions = {
  // eslint-disable-next-line
  async SET_CARGROUPS_ALL({ state, dispatch, commit }) {
    const payload = "";
    if (state.cargroup.length > 0) {
      const groups = state.cargroup;
      const groupFilter = [];
      for (const key in groups) {
        if (Object.hasOwnProperty.call(groups, key)) {
          const vehicleCategory = groups[key].vehicle_category.id;
          if (
            groupFilter.find((group) => group == vehicleCategory) == undefined
          ) {
            groupFilter.push(vehicleCategory);
          }
        }
      }
      commit("MUTATE_GROUPFILTER_GLOBAL", groupFilter);
      groups.forEach((element) => {
        element.price = null;
      });
      await commit("MUTATE_CARGROUPS", groups);
      return true;
    }
    let object = [];
    await apiCall.get("/api/rent/groups", payload, (groups) => {
      const groupFilter = [];
      for (const key in groups) {
        if (Object.hasOwnProperty.call(groups, key)) {
          const vehicleCategory = groups[key].vehicle_category.id;
          if (
            groupFilter.find((group) => group == vehicleCategory) == undefined
          ) {
            groupFilter.push(vehicleCategory);
          }
        }
      }
      commit("MUTATE_GROUPFILTER_GLOBAL", groupFilter);
      object = groups;
      object.forEach((element) => {
        element.price = null;
      });
    });

    await commit("MUTATE_CARGROUPS", object);
    return true;
  },
  async SET_CARGROUP_IMAGE({ state, commit }, id) {
    const payload = "";

    if (state.groupimages[id]) {
      return state.groupimages[id];
    }
    let image = null;
    await apiCall.get(
      "/api/rent/groups/" + id + "/images",
      payload,
      (result) => {
        const data = JSON.stringify(result);
        image = data;
        commit("MUTATE_GROUP_IMAGE", { id: id, result: result });
      }
    );

    return JSON.parse(image);
  },
  async GET_CARGROUP_FROM_STATION({ commit }) {
    const startstation = await store.getters["userSession/GET_STATION"];

    // eslint-disable-next-line
    const payload = { station_id: startstation };

    let groups = Object;

    //  + station // soon station ...
    await apiCall.get("/api/rent/groups", payload, (result) => {
      const groupFilter = [];
      for (const key in result) {
        if (Object.hasOwnProperty.call(result, key)) {
          const vehicleCategory = result[key].vehicle_category.id;
          if (
            groupFilter.find((group) => group == vehicleCategory) == undefined
          ) {
            groupFilter.push(vehicleCategory);
          }
        }
      }
      commit("MUTATE_GROUPS_FROM_STATION", result);
      commit("MUTATE_GROUPFILTER_FROM_STATION", groupFilter);
      groups = result;
    });
    return groups;
  },
  // eslint-disable-next-line
  async SET_AVAILABILITIES({ commit }, groupKey) {
    const date = await store.getters["userSession/GET_RESERVATIONDATE"];
    const time = await store.getters["userSession/GET_RESERVATIONTIME"];
    const startstation = await store.getters["userSession/GET_STATION"];

    let startTimeDate = "";
    let endTimeDate = "";
    let st = "";
    const timeNow = formatHour(new Date());

    if (date.start) {
      if (time.start) {
        st = time.start + ":00";
      } else {
        st = timeNow + ":00";
      }
      startTimeDate = date.start + "T" + st;
    } else {
      startTimeDate = formateDatetime(Date.now());
    }
    if (date.end) {
      // console.log('time', time.end);
      if (time.end) {
        st = time.end + ":00";
      } else {
        st = timeNow + ":00";
      }
      endTimeDate = date.end + "T" + st;
    } else {
      endTimeDate = formateDatetime(Date.now());
    }
    const payload = {
      // eslint-disable-next-line
      startstation_id: startstation,
      // eslint-disable-next-line
      planned_group_id: groupKey,
      // eslint-disable-next-line
      startdate: startTimeDate,
      // eslint-disable-next-line
      planned_enddate: endTimeDate,
    };
    // eslint-disable-next-line
    let result = Object;
    await apiCall.get("/api/rental/groups/availabilities", payload, (group) => {
      result = group;
    });
    return result;
  },
  SET_GROUPIMAGES({ commit }, images) {
    commit("MUTATE_SESSION_GROUPIMAGES", images);
  },
};

// mutations
const mutations = {
  MUTATE_CARGROUPS(state, groups) {
    state.cargroup = groups;
    localStorage.setItem(ENV + "groups", JSON.stringify(groups));
  },
  MUTATE_SESSION_GROUPIMAGES(state, images) {
    state.groupimages = images;
  },
  MUTATE_GROUP_IMAGE(state, payload) {
    state.groupimages[payload.id] = payload.result;
    // sessionStorage.setItem(
    //   ENV + "groupimages",
    //   JSON.stringify(state.groupimages)
    // );
  },
  MUTATE_GROUPS_FROM_STATION(state, groups) {
    state.selectedStationCargroup = groups;
    localStorage.setItem(
      ENV + "selectedStationCargroup",
      JSON.stringify(groups)
    );
  },
  MUTATE_GROUPFILTER_FROM_STATION(state, groupfilter) {
    state.selectedStationGroupfilter = groupfilter;
  },
  MUTATE_GROUPFILTER_GLOBAL(state, groupfilter) {
    state.globalGroupfilter = groupfilter;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
