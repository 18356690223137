
import { defineComponent } from "vue";
import store from "@/store";
import datetime from "@/components/reservation/date_time.vue"; // @ is an alias to /src

import stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src

export default defineComponent({
  name: "infobar_steep3",
  components: { stationpicker, datetime },
  setup() {
    const stations = store.getters["stations/GET_STATIONS"];

    return {
      stations,
      store,
    };
  },
  data() {
    return {
      stationSelected: store.getters["userSession/GET_STATION"],
      stationSelectedEnd: store.getters["userSession/GET_STATION_END"],
    };
  },
  props: {
    data: Object,
    type: String,
    step: Number,
  },
});
