<template>
  <div class="orm-container-fluid orm-reservationInfobar">
    <div class="orm-container">
      <div class="orm-row">
        <div class="orm-col-xs-12 orm-py-16">
          <div v-if="step == 'success'"><p v-html="i18n('success/title')"></p></div>
          <div v-else>
            <p style="margin-top:20px;" v-html="i18n('step4/title')">
             
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "reservationheader",
  components: {},
  props: {
    step: String,
  },
});
</script>
