
import { defineComponent } from "vue";

export default defineComponent({
  name: "reservationheader",
  components: {},
  props: {
    step: String,
  },
});
