import { createStore, createLogger } from "vuex";

import general from "./modules/app/general";
import userSession from "./modules/app/userSession";

import ORMgeneral from "./modules/orm/general";
import stations from "./modules/orm/stations";
import cargroup from "./modules/orm/cargroup";
import reservation from "./modules/orm/reservation";
import customer from "./modules/orm/customer";
import offers from "./modules/orm/offers";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    general: general,
    userSession: userSession,
    ORMgeneral: ORMgeneral,
    stations: stations,
    cargroup: cargroup,
    reservation: reservation,
    customer: customer,
    offers: offers,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
