<template>
  <div class="orm-input">
    <label class="orm-form__label" :for="myId">{{ myLabel }}</label>
    <textarea
      class="orm-input__control"
      :id="myId"
      :value="modelValue"
      :placeholder="myPlaceholder"
      :readonly="isReadonly"
      :rows="myRows"
      @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
  </div>
</template>
<script>
export default {
  props: {
    myId: {
      type: String,
      required: true,
    },
    myLabel: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    myPlaceholder: {
      type: String,
      required: false,
      default: "",
    },
    myRows: {
      type: String,
      required: false,
      default: "3",
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
