<template>
  <div class="orm-input">
    <label class="orm-form__label" :for="myId"
      >{{ myLabel }}<span v-if="isRequired">*</span></label
    >
    <input
      class="orm-input__control"
      :id="myId"
      :type="myType"
      :value="modelValue"
      :placeholder="myPlaceholder"
      :readonly="isReadonly"
      :required="isRequired"
      :class="{ 'orm-form__error': error}"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>
<script>
export default {
  props: {
    myId: {
      type: String,
      required: true,
    },
    myLabel: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    myPlaceholder: {
      type: String,
      required: false,
      default: "",
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    myType: {
      type: String,
      required: false,
      default: "text",
    },
  },
  emits: ["update:modelValue"],
};
</script>
