import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);

app.mixin({
  methods: {
    i18n(id) {
      return store.getters["general/GET_TRANSLATION"](id);
    },
  },
});

// app.config.devtools = true;

app.directive("click-outside", {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
    document.body.addEventListener("touch", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
    document.body.removeEventListener("touch", el.clickOutsideEvent);
  },
});

// app.mixin({
//   created() {
//     const myOption = this.$options.myOption;
//     if (myOption) {
//       console.log(myOption);
//     }
//   },
// });

app
  .use(store)
  .use(router)
  .mount("#crentReservation");
