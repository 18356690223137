
import { defineComponent } from "vue";

import datepicker from "@/components/ui/datepicker.vue"; // @ is an alias to /src
import stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src
import store from "@/store";
// import routes from "@/router/index";

export default defineComponent({
  name: "SearchBar",
  components: { datepicker, stationpicker },
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentSearchBar");
    if (myEle !== null) {
      widget = true;
    }

    return {
      widget,
      store,
      // i18n,#
    };
  },
  data() {
    return {
      station: Object,
      loading: false,
      ormload: false,
      stations: {},
    };
  },
  async mounted() {
    this.ormload = await store.getters["general/GET_ORMLOADED"];
    this.stations = await store.getters["stations/GET_STATIONS"];
    this.station = await store.getters["userSession/GET_STATION"];
    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["general/GET_ORMLOADED"],
      (val) => {
        this.ormload = val;
        this.stations = store.getters["stations/GET_STATIONS"];
        this.station = store.getters["userSession/GET_STATION"];
      }
    );

    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["userSession/GET_STATION"],
      (val) => {
        this.station = store.getters["stations/GET_STATION_BY_ID"](val);
      }
    );
  },
  methods: {
    async getCars() {
      const times = await store.getters["stations/GET_OPENINGTIMES"];

      if (times) {
        this.loading = true;
        if (process.env.VUE_APP_STANDALONE == "true") {
          this.$router.push({ name: 'Reservation_Step1'});
        } else {
          window.location.href = process.env.VUE_APP_RESERVATION_PATH + process.env.VUE_APP_RESERVATION_PATH_STEP1;
        }
      }
    },
  },
});
