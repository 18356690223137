<template>
  <div v-click-outside="closeEvent">
    <div
      class="orm-button__dropdown"
      :class="{ closed: !closed }"
      @click="closed = !closed"
    >
      {{ label }}
      <i
        class="fa"
        :class="{ 'fa-caret-up': !closed, 'fa-caret-down': closed }"
      ></i>
    </div>
    <div class="orm-filter__dropdown text-left" :class="{ closed: closed }">
      <div
        v-for="station in data"
        :key="station"
        class="orm-filter__dropdown__item"
      >
        <div @click="closed = true">
          <router-link
            :to="{ name: 'station', params: { name: slugMe(station.name) } }"
            >{{ station.name }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { slugMe } from "@/constants/formater";
import store from "@/store";

export default defineComponent({
  name: "dropdown",
  props: {
    data: Array,
    label: String,
  },
  setup() {
    const selectedStation = store.getters["userSession/GET_STATION"];
    return {
      slugMe,
      selectedStation,
    };
  },
  data() {
    return {
      closed: true,
    };
  },
  methods: {
    closeEvent() {
      this.closed = true;
    },
  },
});
</script>
