<template>
  <Header />
  <div class="orm-background--cta1">
    <div class="orm-container">
      <div class="orm-row">
        <div class="orm-col-xs-12">
          <div class="orm-row">
            <div class="orm-carfilter__element orm-col-xs-12 orm-col-sm-12">
              <filterSelect
                v-if="selectedGroup"
                :groupFilter="groupFilter"
                :preselect="selectedGroup"
                @update:dataPool="updateFilter"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="orm-row">
        <div class="orm-col-xs-12">
          <div v-if="carGroup.length > 0">
            <Car
              v-for="car in carGroup"
              :key="car.id"
              :data="car"
              :filter="showCar(car.vehicle_category.id)"
              :vehicles-list="true"
            />
          </div>
          <div
            v-if="carGroup.length == 0"
            style="height:400px"
            class="orm-row orm-pb-60 center-xs"
          >
            <div class="orm-preload-page">
              <i class="fa fa-spinner-third orm-rotate360"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>
<script>
import store from "@/store";
import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import Header from "@/components/widgets/Header.vue"; // @ is an alias to /src
import Car from "@/components/cargroup/car.vue";
import filterSelect from "@/components/ui/filter-select.vue";

import { slugMe } from "@/constants/formater";

export default {
  components: { Footer, Header, Car, filterSelect },
  props: ["name"],
  data() {
    return {
      carGroup: [],
      groupFilter: [],
      selectedAll: true,
      selectedGroup: false,
    };
  },
  async mounted() {
    await store.dispatch("cargroup/SET_CARGROUPS_ALL");

    this.carGroup = await store.getters["cargroup/GET_GLOBAL_CARGROUPS"];

    this.groupFilter = await store.getters["cargroup/GET_GLOBAL_GROUPFILTER"];

    if (
      this.$route.params.name &&
      this.groupFilter.find(
        (element) => slugMe(element) == this.$route.params.name
      )
    ) {
      this.selectedGroup = {};
      this.selectedGroup[
        this.groupFilter.find(
          (element) => slugMe(element) == this.$route.params.name
        )
      ] = true;
      store.dispatch("userSession/SET_CARCATEGORY", this.selectedGroup);
      this.selectedAll = false;
    } else {
      this.selectedGroup = await store.getters["userSession/GET_CARCATEGORY"];
      if (Object.keys(this.selectedGroup).length) {
        this.selectedAll = false;
      }
    }
  },
  methods: {
    showCar(group) {
      if (this.selectedGroup[group] || this.selectedAll) {
        return true;
      } else {
        return false;
      }
    },
    updateFilter(ev) {
      this.selectedGroup = ev.selectedGroup;
      this.selectedAll = ev.selectedAll;
    },
  },
};
</script>
