<template>
  <div
    class="orm-mb-16 orm-col-xs-12 orm-col-sm-8 orm-col-md-6 orm-col-lg-4 orm-px-xs-8"
    v-if="filter"
  >
    <div class="orm-caroffers__element">
      <div class="orm-slider__image__container">
        <Slider v-if="images" :images="images" :autoplay="false" />
        <div v-else :style="{ paddingTop: '75%' }"></div>
      </div>

      <div class="orm-cartitle">
        <h2 class="orm-headline__h2">
          {{ data[env.VUE_APP_RESERVATION_CARGROUP] }}
        </h2>
        <p class="orm-headline__h2__subheadline orm-relative">
          {{ i18n("cargroup/similar") }} | {{ data[env.VUE_APP_RESERVATION_CARCATEGORY] }}
          <i class="fal fa-question-circle orm-cargroup__tooltip"
            ><span
              class="orm-cargroup__tooltip_text orm-cargroup__tooltip-bottom"
              >{{ i18n("cargroup/noguarantee") }}</span
            ></i
          >
        </p>
      </div>
      <div class="orm-cardetails">
        <div class="orm-carequip">
          <p class="orm-equip__txt__icons">
            <equipIcon :data="data.features" :shortlist="true" />
          </p>
          <p class="orm-equip__txt__freemiles" v-if="price.length > 0">
            <span v-if="price && !price[0].all_km_inclusive"
              >{{ i18n("cargroup/inclusive") }} {{ price[0].km_free }}
              {{ i18n("cargroup/freeKilometers") }}</span
            >
            <span v-else
              >{{ i18n("cargroup/inclusive") }}
              {{ i18n("cargroup/unlimited") }}
              {{ i18n("cargroup/freeKilometers") }}</span
            >
          </p>
        </div>
        <div class="orm-carprice">
          <div>
            <p class="orm-price__txt__surtitle" v-if="price.length > 0">
              {{ i18n("cargroup/unitbefore") }} {{ price[0].unit }}
              {{ i18n("cargroup/unitafter") }}
            </p>
            <p class="orm-price__txt" v-if="price.length > 0">
              <span style="white-space:nowrap;">{{
                convertCurrency(price[0].long_price)
              }}</span>
            </p>

            <p class="orm-price__txt" v-if="price.length == 0 && loading">
              <i class="fa fa-spinner-third orm-rotate360"></i>
            </p>

            <p class="orm-price__txt__subtitle" v-if="price.length > 0">
              ({{ i18n("cargroup/total") }}:
              {{ convertCurrency(tarif.total_price_main_rates) }})
            </p>
          </div>
        </div>
      </div>
      <div class="orm-carbutton">
        <button
          v-if="price.length > 0 && !loading"
          @click="setCarGroup(car)"
          class="orm-button__cta outline small"
        >
          {{ i18n("cargroup/select") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import store from "@/store";
import equipIcon from "@/components/reservation/equip-icon.vue";
import Slider from "@/components/ui/image-slider.vue";
import { convertCurrency } from "@/constants/formater";
export default defineComponent({
  name: "car-list-small",
  components: { equipIcon, Slider },
  setup() {
    const userSession = store.state.userSession.LocalSession;

    return {
      userSession,
      store,
      convertCurrency,
    };
  },
  data() {
    return {
      car: Object,
      price: [],
      images: null,
      show: true,
      loading: true,
      tarif: Object,
      env: process.env,
    };
  },
  props: {
    data: Object,
    filter: Boolean,
  },
  async mounted() {
    this.car = await JSON.parse(JSON.stringify(this.data));

    this.car.price = {};

    const observer = new IntersectionObserver((entries) => {
      // Use `intersectionRatio` because of Edge 15's
      // lack of support for `isIntersecting`.
      // See: https://github.com/w3c/IntersectionObserver/issues/211
      if (entries[0].intersectionRatio <= 0) return;
      // Cleanup the observer when it's not
      // needed anymore.
      observer.unobserve(this.$el);
      // The `loadPrice()` resolves
      // to the result of a dynamic `import()`
      // which is passed to the `resolveComponent()`
      // function.
      this.lazyload();
    });
    // We observe the root `$el` of the
    // mounted loading component to detect
    // when it becomes visible.
    observer.observe(this.$el);
  },
  methods: {
    async lazyload() {
      const car = [];

      await store
        .dispatch("cargroup/SET_CARGROUP_IMAGE", this.car.id)
        .then((val) => (this.images = val));
      this.tarif = await store
        .dispatch("reservation/CALCULATE_PRICE", {
          id: this.car.id,
          extras: false,
        })
        .then((res) => {
          if (!res.errors) {
            res.main_rates.forEach((element) => {
              if (element.position == 1) {
                car.push(element);
              }
            });
            this.loading = false;
            return res;
          } else {
            this.$emit("update:error", res);
          }
        });
      this.price = car;
    },
    setCarGroup(car) {
      store.dispatch("userSession/SET_CARGROUP", car.id).then(() => {
        store
          .dispatch("reservation/SET_RESERVATION_TARIFS", this.tarif)
          .then(() => {
            this.$router.push({ name: "Reservation_Step2" });
          });
      });
    },
  },
});
</script>
