<template>
  <CarListSmall
    v-if="!availabilityPlan && filter && !vehiclesList"
    :data="data"
    :filter="filter"
    @update:error="showError"
  />

  <CarListAvaiabilites
    v-if="availabilityPlan && filter && !vehiclesList"
    :data="data"
    :filter="filter"
    @update:error="showError"
  />

  <CarListSearch
    v-if="filter && vehiclesList"
    :data="data"
    :filter="filter"
  />
</template>

<script>
import { defineComponent } from "vue";
// import store from "@/store";
import CarListSmall from "@/components/cargroup/car-list-small.vue";
import CarListAvaiabilites from "@/components/cargroup/car-list-availabilities.vue";
import CarListSearch from "@/components/cargroup/car-list-search.vue";
export default defineComponent({
  name: "car",
  components: { CarListSmall, CarListAvaiabilites, CarListSearch },

  props: {
    availabilityPlan: { type: Boolean, default: false },
    data: Object,
    filter: Boolean,
    vehiclesList: { type: Boolean, default: false },
  },
  methods: {
    showError(ev) {
      console.log(ev);
      this.error.show = true;
      //this.error.data.content = ev.form.message;
    },
  },
});
</script>
