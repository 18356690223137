<template>
  <teleport v-if="widget" to="#crentSearchBar ">
    <div
      class="orm-py-56 orm-py-sm-70 orm-container-fluid orm-background__image"
      :style="{
        backgroundImage:
          'url(' +
          store.getters['general/GET_CLIENT_DATA']['background-searchbar'] +
          ')',
      }"
    >
      <div class="orm-container">
        <div class="orm-row center-xs">
          <div class="orm-col-xs-12 text-center">
            <h1 class="orm-searchbar__title orm-headline__h1 orm-pb-sm-128">
              {{ i18n("modules/searchbar/title") }}
            </h1>
          </div>
        </div>
        <div class="orm-row center-xs">
          <div
            class="orm-searchbar__datepicker orm-col-xs-12 orm-col-sm-11 orm-col-md-11 orm-col-lg-11"
          >
            <div class="orm-searchbar__padding" v-if="ormload">
              <div class="orm-row center-lg">
                <div class="orm-col-xs-12 orm-col-md-12 orm-col-lg-3">
                  <div class="orm-searchbar__headline">
                    <strong>{{ i18n("general/station") }}</strong>
                  </div>
                  <div class="orm-row">
                    <stationpicker
                      :data="stations"
                      type="start"
                      class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-12"
                    />
                    <stationpicker
                      v-if="
                        store.getters['ORMgeneral/GET_PARAMETER'](
                          'BINET_ALLOW_ONEWAY'
                        ) == 1
                      "
                      :data="stations"
                      type="end"
                      class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-12"
                    />
                  </div>
                </div>
                <div
                  class="orm-col-xs-12 orm-col-lg-7"
                  style="position:relative"
                >
                  <datepicker />
                </div>
                <div
                  class="text-right orm-col-xs-12 orm-col-sm-6 orm-col-sm-offset-6 orm-col-lg-2 orm-col-lg-offset-0"
                >
                  <div class="orm-searchbar__headline">
                    <strong>&nbsp;</strong>
                  </div>
                  <button
                    v-if="!loading"
                    @click="getCars('/reservation/step1')"
                    class="orm-btn orm-btn--large orm-btn--searchbar"
                    :class="[station ? '' : 'disable']"
                  >
                    {{ i18n("general/search") }}
                  </button>
                  <button
                    class="orm-btn orm-btn--large orm-btn--searchbar disable"
                    v-else
                  >
                    <i class="fa fa-spinner-third orm-rotate360"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import datepicker from "@/components/ui/datepicker.vue"; // @ is an alias to /src
import stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src
import store from "@/store";
// import routes from "@/router/index";

export default defineComponent({
  name: "SearchBar",
  components: { datepicker, stationpicker },
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentSearchBar");
    if (myEle !== null) {
      widget = true;
    }

    return {
      widget,
      store,
      // i18n,#
    };
  },
  data() {
    return {
      station: Object,
      loading: false,
      ormload: false,
      stations: {},
    };
  },
  async mounted() {
    this.ormload = await store.getters["general/GET_ORMLOADED"];
    this.stations = await store.getters["stations/GET_STATIONS"];
    this.station = await store.getters["userSession/GET_STATION"];
    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["general/GET_ORMLOADED"],
      (val) => {
        this.ormload = val;
        this.stations = store.getters["stations/GET_STATIONS"];
        this.station = store.getters["userSession/GET_STATION"];
      }
    );

    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["userSession/GET_STATION"],
      (val) => {
        this.station = store.getters["stations/GET_STATION_BY_ID"](val);
      }
    );
  },
  methods: {
    async getCars() {
      const times = await store.getters["stations/GET_OPENINGTIMES"];

      if (times) {
        this.loading = true;
        if (process.env.VUE_APP_STANDALONE == "true") {
          this.$router.push({ name: 'Reservation_Step1'});
        } else {
          window.location.href = process.env.VUE_APP_RESERVATION_PATH + process.env.VUE_APP_RESERVATION_PATH_STEP1;
        }
      }
    },
  },
});
</script>
