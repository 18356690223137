<template>
  <div class="orm-container-fluid orm-reservationInfobar">
    <div class="orm-container">
      <div class="orm-row center-xl left-lg">
        <div class="orm-col-xs-12 orm-col-md-8 orm-col-lg-11 orm-padding20">
          <div class="orm-row">
            <stationpicker class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-3" :data="stations" type="start" :readonly="true" />
            <div class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-3" v-if="stationSelected != stationSelectedEnd">
            <stationpicker :data="stations" type="end" :readonly="true" v-if="store.getters['ORMgeneral/GET_PARAMETER']('BINET_ALLOW_ONEWAY') == 1" />
            </div>
            <div class="orm-relative orm-col-xs-12 orm-col-lg-6">
              <datetime />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import store from "@/store";
import datetime from "@/components/reservation/date_time.vue"; // @ is an alias to /src

import stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src

export default defineComponent({
  name: "infobar_steep3",
  components: { stationpicker, datetime },
  setup() {
    const stations = store.getters["stations/GET_STATIONS"];

    return {
      stations,
      store,
    };
  },
  data() {
    return {
      stationSelected: store.getters["userSession/GET_STATION"],
      stationSelectedEnd: store.getters["userSession/GET_STATION_END"],
    };
  },
  props: {
    data: Object,
    type: String,
    step: Number,
  },
});
</script>
