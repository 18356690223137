import apiCall from "../../api/crentAPI";
const ENV = process.env.NODE_ENV === "production" ? "ORM_" : "";

/* eslint-disable */

const state = () => ({
  countries: [],
  salutations:
    JSON.parse(sessionStorage.getItem(ENV + "selectedSalutations")) || [],
  customer: {
    customer: {
      salutation_id: "",
      email: "",
      firstname: "",
      lastname: "",
      company_name: "",
      street: "",
      zipcode: "",
      city: "",
      country_id: "",
      phone_number: "",
      allow_marketing: false,
      create_login: false,
    },
    payment_method_id: "",
    remarks: "",
  },
});
/* eslint-enable */
const getters = {
  GET_COUNTRIES: (state) => state.countries || null,
  GET_COUNTRY: (state) => (lang) => {
    return state.countries.find((countrie) => countrie.sign === lang);
  },
  GET_SALUTATIONS: (state) => state.salutations || null,
  GET_SALUTATION: (state) => (id) => {
    return state.salutations.find(
      (salutation) => salutation.id === parseInt(id)
    ).salutation;
  },
  GET_CUSTOMER: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedCustomer")) ||
    JSON.parse(JSON.stringify(state.customer)),
};
// actions
const actions = {
  async SET_COUNTRIES({ commit }) {
    await apiCall.get("/api/rental/customers/countries", "", (result) => {
      commit("MUTATE_COUNTRIES", result);
    });

    return true;
  },
  async SET_SALUTATIONS({ commit }) {
    await apiCall.get("/api/rental/customers/salutations", "", (result) => {
      commit("MUTATE_SALUTATIONS", result);
    });

    return true;
  },
};
const mutations = {
  MUTATE_COUNTRIES(state, countries) {
    state.countries = countries;
  },
  MUTATE_SALUTATIONS(state, salutations) {
    state.salutations = salutations;
    sessionStorage.setItem(
      ENV + "selectedSalutations",
      JSON.stringify(salutations)
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

//   customer: {
//     salutation_id: "",
//     email: "",
//     firstname: "",
//     lastname: "",
//     title: "",
//     company_name: "",
//     street: "",
//     zipcode: "",
//     city: "",
//     birth_date: "" /* 2021-02-05T09:38:59.310Z */,
//     country_name: "",
//     country_id: "54",
//     phone_number: "",
//     drivers_license_number: "",
//     drivers_license_date: "" /* 2021-02-05T09:38:59.310Z */,
//     drivers_license_city: "",
//     pass_number: "",
//     pass_date: "" /* 2021-02-05T09:38:59.310Z */,
//     pass_city: "",
//     allow_marketing: true,
//     create_login: false,
//   },

//   paymentMethod_id: "",
//   remarks: "",
//   delivery_street: "",
//   delivery_zipcode: "",
//   delivery_city: "",
//   pickup_street: "",
//   pickup_zipcode: "",
//   pickup_city: "",
// },
