export default {
  COLOR_DKBLUE: "#25313E",
  COLOR_TURQUISE: "#00A1BE",
  COLOR_RED: "#E42022",
  COLOR_WHITE: "#ffffff",
  COLOR_MEDGREY: "#e9e9e9",
  COLOR_MEDGREY2: "#dedede",
  COLOR_DKGREY: "#333333",
  COLOR_LTGREY: "#f5f5f5",
  COLOR_LTGREY2: "#f2f2f2",
  COLOR_LTGREY3: "#fefefe",
};
