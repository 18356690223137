
import { mapSettings } from "@/constants/mapSettings";
import { getDistanceFromLatLonInKm } from "@/constants/formater";

import { defineComponent, watchEffect } from "vue";

import gMap from "@/components/maps/gMap.vue";
import gMapMarker from "@/components/maps/gMapMarker.vue";
import gMapUser from "@/components/maps/gMapUser.vue";

import stationlist from "@/components/stationlist.vue";

import store from "@/store";

export default defineComponent({
  name: "LocationMap",
  components: { gMap, gMapMarker, gMapUser, stationlist },
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentLocationMap");
    if (myEle !== null) {
      widget = true;
    }
    const stations = store.state.stations;
    // let selectedStation = store.getters["userSession/GET_STATION"];
    let selectedStation = "";
    const userSession = store.state.userSession.LocalSession;

    watchEffect(() => {
      selectedStation = store.getters["userSession/GET_STATION"];
      // console.log("change", selectedStation);
      // if (selectedStation.station != undefined)
      //   store.dispatch(
      //     "cargroup/GET_CARGROUP_FROM_STATION",
      //     selectedStation.station
      //   );
    });

    const mapConfig = {
      ...mapSettings,
      center: { lat: 52.29240999999999, lng: 8.920219999999972 },
    };

    return {
      widget,
      stations,
      selectedStation,
      getDistanceFromLatLonInKm,
      userSession,
      mapConfig,
      store,
    };
  },

  data() {
    return {
      merchantId: process.env.VUE_APP_CONCARDIS_MERCHANT_ID,
      apiKey: process.env.VUE_APP_MAPS_API_KEY,
    };
  },
  methods: {
    infoWindow() {
      // console.log("Click: ", ev);
    },
  },
});
