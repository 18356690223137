<template>
  <div v-if="!shortlist">
    <div
      class="orm-equip__icon orm-mb-16"
      v-for="feature in data"
      :key="feature"
    >
      <span v-if="icons[feature.css_icon]">
        <i v-if="feature.value == 'Manuell'" class="orm_icon_gear"></i>
        <i v-else class="" :class="icons[feature.css_icon]"></i>
        {{ feature.value }} {{ feature.feature }}</span
      >
      <span v-else>{{ feature.feature }}: {{ feature.value }}</span>
    </div>
  </div>
  <div v-else>
    <span class="orm-equip__icon" v-for="feature in data" :key="feature">
      <span
        v-if="icons[feature.css_icon]"
        :title="feature.feature + ': ' + feature.value"
      >
        <i v-if="feature.value == 'Manuell'" class="orm_icon_gear"></i>
        <i v-else class="" :class="icons[feature.css_icon]"></i>
        <span v-if="feature.id == '1' || feature.id == '2' || feature.id == '3'"
          >&nbsp;{{ feature.value }}</span
        >
      </span>
    </span>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "equip-icon",
  data() {
    return {
      car: Object,
      images: String,
      icons: {
        carIconPerson: "orm_icon_sitzer",
        carIconDoor: "orm_icon_tuerer",
        carIconLuggage: "orm_icon_luggage",
        carIconGear: "orm_icon_automatik",
        carIconGearManual: "orm_icon_gear",
        carIconSnow: "orm_icon_klima",
        carIconTow: "orm_icon_ahk",
        carIconWeight: "orm_icon_weight",
        carIconBox: "orm_icon_lkwkoffer",
        carIconLabel: "orm_icon_co2",
        null: "orm_icon_navi",
      },
      // title: {  Darf es so nicht gegeben, die Texte für diese icons sind pro geärt anders und kommen anders aus jeder orm schnittstelle
      //   carIconPerson: "Sitzplätze",
      //   carIconDoor: "Türen",
      //   carIconLuggage: "Gepäckstücke",
      //   carIconGear: "Getriebeart",
      //   carIconSnow: "Klimaanlage",
      //   carIconTow: "Anhängerkupplung",
      //   carIconWeight: "Zulässiges Anhängergewicht",
      //   carIconBox: "Abmaße",
      //   carIconLabel: "keine Ahnung",
      //   null: "Navi",
      // },
    };
  },
  props: {
    data: Object,
    shortlist: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});
</script>
