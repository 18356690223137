<template>
  <Header />
  <SearchBar />
  <OffersSlider :type="1" />

  <OffersSlider :type="2" />

  <LocationMap />
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import OffersSlider from "@/components/widgets/OffersSlider.vue"; // @ is an alias to /src
import LocationMap from "@/components/widgets/LocationMap.vue"; // @ is an alias to /src
import SearchBar from "@/components/widgets/SearchBar.vue"; // @ is an alias to /src
import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import Header from "@/components/widgets/Header.vue"; // @ is an alias to /src
import store from "@/store";

//import store from "../store";

export default defineComponent({
  name: "Home",
  components: {
    OffersSlider,
    LocationMap,
    SearchBar,
    Footer,
    Header,
  },

  mounted() {
    store.dispatch("userSession/SET_CARCATEGORY", {});
    // console.log($store.state.count);
  },
});
</script>
