<template>
  <ErrorModal v-if="error.show" :data="error.data" :load-times="true" />
  <reservationheader :step="1" />
  <reservationinfobar :step="1" />

  <div class="orm-container-fluid orm-reservationCarselect">
    <div class="orm-container">
      <div class="orm-row">
        <div class="orm-col-xs-12">
          <div class="orm-row">
            <div class="orm-carfilter__element orm-col-xs-12 orm-col-sm-12">
              <filterSelect
                v-if="carGroup.length > 0 && !selectwait"
                :groupFilter="groupFilter"
                :preselect="selectedGroup"
                @update:dataPool="updateFilter"
              />
            </div>
            <!-- <div class="orm-carfilter__element orm-col-xs-12 orm-col-sm-3">
              <a href="#" class="orm-button__dropdown">Ausstattung</a>
            </div> -->
            <!-- <div class="orm-carfilter__element orm-col-xs-12 orm-col-sm-6">
              <form action="#">
                <input type="checkbox" id="test1" />
                <label for="test1">nur kontaktlos mieten</label>
                <a href="#" class="orm-carfilter__infolink"
                  ><i class="fal fa-question-circle"></i
                ></a>
              </form>
            </div> -->
          </div>
        </div>
      </div>

      <div class="orm-row">
        <div class="orm-col-xs-12">
          <div
            class="orm-row center-xs start-md"
            v-if="carGroup.length > 0 && !isLoading"
          >
            <Car
              v-for="car in carGroup"
              :key="car.id"
              :data="car"
              :filter="showCar(car.vehicle_category.id)"
              :availability-plan="availabilityPlan"
            />
          </div>
          <div
            class="text-center orm-mt-64 orm-mb-94"
            v-else-if="carGroup.length == 0 && !isLoading"
            v-html="i18n('step1/nocars')"
          ></div>
          <div
            class="orm-preload-page orm-preload-page-static orm-mt-64"
            v-else
          >
            <div class="orm-loader-spinner">
              <i class="fa fa-spinner-third orm-rotate360"></i>
            </div>
          </div>
          <div
            class="orm-col-xs-12 orm-background--white orm-py-30 orm-px-30"
            v-if="i18n('step1/description') && !isLoading"
            v-html="i18n('step1/description')"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { defineComponent } from "vue";
import reservationheader from "@/components/reservation/header.vue"; // @ is an alias to /src
import reservationinfobar from "@/components/reservation/infobar.vue"; // @ is an alias to /src
import Car from "@/components/cargroup/car.vue";

import filterSelect from "@/components/ui/filter-select.vue";
import ErrorModal from "@/components/modal-error.vue";

import store from "@/store";
import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import { slugMe } from "@/constants/formater";

export default defineComponent({
  name: "ReservationStep1",
  props: { data: Object, type: String },
  data() {
    return {
      stationSelected: String,
      carGroup: [],
      groupFilter: [],
      selectwait: true,
      selectedAll: true,
      selectedGroup: {},
      isLoading: false,
      error: {
        show: false,
        data: {
          header: this.i18n("error/default/header"),
          content: this.i18n("error/default/content"),
          buttonFirst: "Home",
          buttonFirstLabel: this.i18n("error/default/buttonFirstLabel"),
        },
      },
    };
  },
  components: {
    reservationheader,
    reservationinfobar,
    Footer,
    Car,
    filterSelect,
    ErrorModal,
  },
  setup() {
    const stations = store.state.stations;
    let availabilityPlan = false;
    if (
      store.getters["ORMgeneral/GET_MODULE"]("ORM_AVAILABILITY_PLAN") == "J"
    ) {
      availabilityPlan = true;
    }
    if (process.env.VUE_APP_AVAILABILTYPLAN == "false") {
      availabilityPlan = false;
    }

    return {
      stations,
      store,
      availabilityPlan,
    };
  },
  async created() {
    this.stationSelected = await store.getters["userSession/GET_STATION"];

    if (this.stationSelected == null) {
      if (process.env.VUE_APP_STANDALONE == "true") {
        this.$router.push({ name: "Home" });
      } else {
        window.location.href = process.env.VUE_APP_RESERVATION_PATH;
      }
      return;
    }

    store.dispatch("reservation/DELETE_TARIF");

    this.loadCarGroup();
    store.watch(
      (state, getters) => getters["userSession/GET_RESERVATIONTIME_START"],
      () => {
        this.loadCarGroup();
      }
    );
    store.watch(
      (state, getters) => getters["userSession/GET_RESERVATIONTIME_END"],
      () => {
        this.loadCarGroup();
      }
    );

    store.watch(
      (state, getters) => getters["userSession/GET_RESERVATIONDATE_START"],
      () => {
        this.loadCarGroup();
      }
    );
    store.watch(
      (state, getters) => getters["userSession/GET_RESERVATIONDATE_END"],
      () => {
        this.loadCarGroup();
      }
    );

    store.watch(
      (state, getters) => getters["userSession/GET_STATION_END"],
      () => {
        this.loadCarGroup();
      }
    );
  },
  methods: {
    async loadCarGroup() {
      this.isLoading = true;
      this.stationSelected = await store.getters["userSession/GET_STATION"];
      this.carGroup = [];

      await store
        .dispatch("cargroup/GET_CARGROUP_FROM_STATION", this.stationSelected)
        .then((res) => {
          const object = res;
          for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
              const element = object[key];
              object.find((x) => x.id === element.id).price = [];
            }
          }
          this.carGroup = object;
          this.isLoading = false;
        });

      this.groupFilter = await store.getters["cargroup/GET_GROUPFILTER"];

      if (
        this.$route.params.name &&
        this.groupFilter.find(
          (element) => slugMe(element) == this.$route.params.name
        )
      ) {
        this.selectedGroup[
          this.groupFilter.find(
            (element) => slugMe(element) == this.$route.params.name
          )
        ] = true;
        store.dispatch("userSession/SET_CARCATEGORY", this.selectedGroup);
        this.selectedAll = false;
        this.selectwait = false;
      } else {
        this.selectedGroup = await store.getters["userSession/GET_CARCATEGORY"];
        if (Object.keys(this.selectedGroup).length) {
          this.selectedAll = false;
        }
        this.selectwait = false;
      }
    },

    showCar(group) {
      if (this.selectedGroup[group] || this.selectedAll) {
        return true;
      } else {
        return false;
      }
    },
    updateFilter(ev) {
      this.selectedGroup = ev.selectedGroup;
      this.selectedAll = ev.selectedAll;
    },
    showError(ev) {
      console.log(ev);
      this.error.show = true;
      //this.error.data.content = ev.form.message;
    },
  },
});
</script>
