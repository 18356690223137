<template>
  <ErrorModal v-if="error.show" :data="error.data" :load-times="true" />
  <reservationheader :step="3" />
  <reservationinfobar :step="3" />

  <div class="orm-container-fluid orm-reservation__data__container">
    <div class="orm-container">
      <div class="orm-row">
        <div class="orm-col-xs-12 orm-col-md-9">
          <div class="orm-reservation__data__container__inner orm-my-16">
            <h1 class="orm-headline__h1">{{ i18n("step3/headline") }}</h1>
            <div class="orm-row">
              <div class="orm-col-xs-12 orm-col-sm-6">
                <h2 class="orm-headline__h3">
                  {{ i18n("form/personaldata") }}
                </h2>
                <div class="orm-form__block orm-form__container">
                  <form-select
                    my-id="formSalutation"
                    :my-label="i18n('form/label/salutation')"
                    :my-options="salutationMethods"
                    my-options-value="id"
                    my-options-text="salutation"
                    :my-placeholder="i18n('form/placeholder/salutation')"
                    :is-required="true"
                    :error="validate.salutation_id"
                    v-model="form.customer.salutation_id"
                  ></form-select>
                  <!-- <form-input
                  my-id="formTitle"
                  my-label="Titel"
                  v-model="form.customer.title"
                ></form-input> -->
                  <form-input
                    my-id="formFirstname"
                    :my-label="i18n('form/label/firstname')"
                    :is-required="true"
                    :error="validate.firstname"
                    v-model="form.customer.firstname"
                  ></form-input>
                  <form-input
                    my-id="formLastname"
                    :my-label="i18n('form/label/lastname')"
                    :is-required="true"
                    :error="validate.lastname"
                    v-model="form.customer.lastname"
                  ></form-input>
                  <form-input
                    my-id="formEmail"
                    :my-label="i18n('form/label/email')"
                    my-type="email"
                    :is-required="true"
                    :error="validate.email"
                    v-model="form.customer.email"
                  ></form-input>
                  <form-input
                    my-id="formPhone"
                    :my-label="i18n('form/label/phone')"
                    my-type="tel"
                    v-model="form.customer.phone_number"
                  ></form-input>
                  <form-input
                    my-id="formCompany"
                    :my-label="i18n('form/label/company')"
                    v-model="form.customer.company_name"
                  ></form-input>
                  <!-- <form-input
                  my-id="formBirthdate"
                  my-label="Geburtsdatum"
                  my-placeholder="tt.mm.jjjj"
                  my-type="date"
                  v-model="form.customer.birth_date"
                ></form-input> -->
                </div>
              </div>
              <div class="orm-col-xs-12 orm-col-sm-6">
                <h2 class="orm-headline__h3">
                  {{ i18n("form/address") }}
                </h2>
                <div class="orm-form__block orm-form__container">
                  <form-input
                    my-id="formStreet"
                    :my-label="i18n('form/label/street')"
                    v-model="form.customer.street"
                  ></form-input>
                  <form-input
                    my-id="formZipcode"
                    :my-label="i18n('form/label/zipcode')"
                    v-model="form.customer.zipcode"
                  ></form-input>
                  <form-input
                    my-id="formCity"
                    :my-label="i18n('form/label/city')"
                    v-model="form.customer.city"
                  ></form-input>
                  <!-- <form-input
                  my-id="formCountryName"
                  my-label="Land"
                  v-model="form.customer.countryName"
                ></form-input> -->
                </div>
                <h2 class="orm-headline__h3">
                  {{ i18n("form/payment") }}
                </h2>
                <div class="orm-form__block orm-form__container">
                  <form-select
                    my-id="formPayment"
                    :my-label="i18n('form/label/payment')"
                    :my-options="paymentMethods"
                    my-options-value="id"
                    my-options-text="payment_method"
                    :my-placeholder="i18n('form/placeholder/payment')"
                    :is-required="true"
                    :error="validate.payment_method_id"
                    v-model="form.payment_method_id"
                  ></form-select>
                </div>
                <div
                  v-if="
                    store.getters['ORMgeneral/GET_MODULE']('ORM_LOGIN') == 'J'
                  "
                >
                  <h2 class="orm-headline__h3">
                    {{ i18n("form/account") }}
                  </h2>
                  <div class="orm-form__block orm-form__container">
                    <form-checkbox
                      my-fontbase="14"
                      my-name="formCreateLogin"
                      :my-label="i18n('form/label/account')"
                      v-model="form.customer.create_login"
                    ></form-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <div class="orm-row">
              <div class="orm-col-xs-12 orm-col-sm-6">
                <h2 class="orm-headline__h3">
                  {{ i18n("form/message") }}
                </h2>
                <div class="orm-form__block orm-form__container">
                  <form-textarea
                    my-id="formRemarks"
                    :my-label="i18n('form/label/remarks')"
                    :my-placeholder="i18n('form/placeholder/remarks')"
                    my-rows="5"
                    v-model="form.remarks"
                  ></form-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="orm-col-xs-12 orm-col-md-3">
          <div class="orm-form__button__container">
            <button @click="sumbit" class="orm-button__cta outline small wide">
              {{ i18n("general/nextstep") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { defineComponent } from "vue";

import reservationheader from "@/components/reservation/header.vue"; // @ is an alias to /src
import reservationinfobar from "@/components/reservation/infobar_disabled.vue"; // @ is an alias to /src
import FormInput from "@/components/ui/INPUTS/FormInput.vue"; // @ is an alias to /src
import FormSelect from "@/components/ui/INPUTS/FormSelect"; // @ is an alias to /src
// import { formateDate } from "@/constants/formater";

import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import store from "@/store";
import FormCheckbox from "@/components/ui/INPUTS/FormCheckbox.vue";
import FormTextarea from "@/components/ui/INPUTS/FormTextarea.vue";
import ErrorModal from "@/components/modal-error.vue";

export default defineComponent({
  name: "ReservationStep3",
  components: {
    Footer,
    reservationheader,
    reservationinfobar,
    FormInput,
    FormSelect,
    FormCheckbox,
    FormTextarea,
    ErrorModal,
  },
  setup() {
    return {
      store,
    };
  },
  data() {
    return {
      /* eslint-disable */
      form: {
        customer: {
          salutation_id: "",
          email: "",
          firstname: "",
          lastname: "",
          company_name: "",
          street: "",
          zipcode: "",
          city: "",
          country_id: "",
          phone_number: "",
          allow_marketing: false,
          create_login: false,
        },
        payment_method_id: "",
        remarks: "",
      },
      paymentMethods: Array,
      salutationMethods: Array,
      language: String,
      filterSection: "commentData",
      validate: {
        email: false,
        firstname: false,
        lastname: false,
        salutation_id: false,
        payment_method_id: false,
      },
      errors: [],
      error: {
        show: false,
        data: {
          header: this.i18n("error/tocarselect/header"),
          content: this.i18n("error/tocarselect/content"),
          buttonFirst: "Reservation_Step1",
          buttonFirstLabel: this.i18n("error/tocarselect/buttonFirstLabel"),
        },
      },
    };
  },
  async mounted() {
    const reservation = await store.getters["reservation/GET_RESERVATION"];

    if (reservation == null) {
      this.error.show = true;
      return false;
    }

    const customer = await store.getters["customer/GET_CUSTOMER"];
    this.form = customer;

    this.form.customer.country_id = await store.getters["customer/GET_COUNTRY"](
      "DE"
    ).id;

    await store.dispatch("reservation/GET_PAYMENT_METHODS").then((result) => {
      this.paymentMethods = result;
      // console.log(result);
    });
    this.language = await store.getters["general/GET_LANGUAGE"];
    const GET_SALUTATIONS = await store.getters["customer/GET_SALUTATIONS"];
    const salutationsArray = [];

    for (const key in GET_SALUTATIONS) {
      if (GET_SALUTATIONS[key].language == this.language) {
        salutationsArray.push(GET_SALUTATIONS[key]);
      }
    }

    this.salutationMethods = salutationsArray;
  },
  methods: {
    async sumbit() {
      // console.log("submit");
      if (this.validateForm())
        await store
          .dispatch("reservation/SET_CUSTOMER", this.form)
          .then((result) => {
            this.$router.push({ name: 'Reservation_Step4'});
          });
    },
    validateForm() {
      this.errors = [];
      for (const formfield in this.validate) {
        if (Object.hasOwnProperty.call(this.validate, formfield)) {
          let element = this.form.customer[formfield];
          if (formfield == "payment_method_id") {
            element = this.form[formfield];
            if (!element) {
              this.validate[formfield] = true;
              this.errors.push(formfield + " required.");
            } else {
              this.validate[formfield] = false;
            }
          } else if (formfield == "email") {
            if (!this.validEmail(element)) {
              this.validate[formfield] = true;
              this.errors.push(formfield + " required.");
            } else {
              this.validate[formfield] = false;
            }
          } else if (!element) {
            this.validate[formfield] = true;
            this.errors.push(formfield + " required.");
          } else {
            this.validate[formfield] = false;
          }
        }
      }

      if (this.errors.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
});
</script>
