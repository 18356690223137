<template>
  <label
    class="orm-checkbox"
    :class="{
      'orm-fontbase14': myFontbase == '14',
      'orm-multisize': myMultisize,
      'orm-disabled': required,
      'orm-withSelect': amountReal == '0',
    }"
  >
    <span class="orm-checkbox__input">
      <input
        type="checkbox"
        :name="myName"
        :checked="checked"
        :disabled="required"
        @click="check($event)"
      />
      <span class="orm-checkbox__control">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          aria-hidden="true"
          focusable="false"
        >
          <path
            fill="none"
            stroke="currentColor"
            stroke-width="4"
            d="M1.73 12.91l6.37 6.37L22.79 4.59"
          />
        </svg>
      </span>
    </span>
    <span v-if="amountReal == 0" class="orm-select orm-select__center">
      <div class="orm-select__control orm-inline">
        <select v-model="newAmount" @change="select()">
          <option value="0">0</option>
          <option
            v-for="index in maxAmount"
            :key="index"
            :selected="newAmount == index"
          >
            {{ index }}
          </option>
        </select>
      </div>
    </span>
    <span v-if="myMultisize" class="orm-checkbox__label">
      <b>{{ myLabel }}&nbsp;</b>{{ mySmall }}</span
    >
    <span v-else class="orm-checkbox__label">
      <span v-if="amountReal == 0" class="orm-select">
        <select
          v-model="newAmount"
          @change="select()"
          class="orm-select__control orm-select__control--small"
        >
          <option value="0">0</option>
          <option
            v-for="index in maxAmount"
            :key="index"
            :selected="newAmount == index"
          >
            {{ index }}
          </option>
        </select> </span
      >{{ myLabel }}</span
    >
  </label>
</template>
<script>
export default {
  props: {
    myFontbase: {
      type: String,
      required: false,
      default: "",
    },
    myMultisize: {
      type: Boolean,
      required: false,
      default: false,
    },
    myName: {
      required: true,
    },
    myLabel: {
      type: String,
      required: true,
    },
    mySmall: {
      type: String,
      required: false,
    },
    modelValue: {
      required: false,
      default: false,
    },
    required: {
      required: false,
      default: false,
    },
    amountReal: Number,
    maxAmount: Number,
  },
  data() {
    return {
      newAmount: 0,
      checked: false,
    };
  },
  emits: ["update:modelValue"],
  mounted() {
    if (this.modelValue) {
      this.checked = true;
      this.newAmount = this.modelValue.amount;
    }
  },
  methods: {
    check() {
      if (!this.modelValue) {
        this.newAmount = 1;
      } else {
        this.newAmount = 0;
      }

      if (!this.required) this.$emit("update:modelValue", this.newAmount);
    },
    select() {
      if (this.newAmount > 0) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      this.$emit("update:modelValue", parseInt(this.newAmount));
    },
  },
};
</script>
