<template>
  <div id="datepicker" class="orm-datepicker orm-datepicker--disabled">
    <div class="orm-datecol orm-pr-sm-8">
      <div class="orm-dateselect orm-row no-margin">
        <div class="orm-col-xs-6 orm-col-md-6 orm-px-8">
          <div><small>{{ i18n("general/date") }}</small></div>
          <div class="orm-datepicker__input orm-pr-16 enable" id="start">
            {{ formateDateShort(date.start) }}
            <i class="fal fa-calendar-alt float-right"></i>
          </div>
        </div>
        <div class="orm-col-xs-6 orm-col-md-6 orm-px-8">
          <div>
            <div><small>{{ i18n("general/time") }}</small></div>
            <div>
              <div>
                <div class="orm-timepicker__input">
                  <span>{{ time.end }}</span> {{ i18n("general/hour") }}
                  <i class="fal fa-clock float-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="orm-datecol orm-pl-sm-8">
      <div class="orm-dateselect orm-row no-margin">
        <div class="orm-col-xs-6 orm-col-md-6 orm-px-8">
          <div><small>{{ i18n("general/date") }}</small></div>
          <div class="orm-datepicker__input orm-pr-16 enable" id="end">
            {{ formateDateShort(date.end) }}
            <i class="fal fa-calendar-alt float-right"></i>
          </div>
        </div>
        <div class="orm-col-xs-6 orm-col-md-6 orm-px-8">
          <div>
            <div><small>{{ i18n("general/time") }}</small></div>
            <div>
              <div>
                <div class="orm-timepicker__input">
                  <span>{{ time.end }}</span> {{ i18n("general/hour") }}
                  <i class="fal fa-clock float-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import store from "@/store";

import { formateDateShort } from "@/constants/formater";

export default defineComponent({
  name: "date_time",
  components: {},
  setup() {
    const date = store.getters["reservation/GET_RESERVATION_DATE"];
    const time = store.getters["reservation/GET_RESERVATION_TIME"];

    return {
      date,
      time,
      formateDateShort,
    };
  },
});
</script>
