
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  name: "App",
  components: {},
  setup() {
    return { store };
  },
  data() {
    return {
      initApp: false,
      initORM: false,
      error: "",
      bar: false,
      loading: store.getters["general/GET_LOADING"],
      merchantId: process.env.VUE_APP_CONCARDIS_MERCHANT_ID,
      maps: process.env.VUE_APP_MAPS_API_KEY,
      logoheight: process.env.VUE_APP_CUSTOMER_LOGO_HEIGHT_LOADING,
      logowidth: process.env.VUE_APP_CUSTOMER_LOGO_WIDTH_LOADING,
    };
  },
  async mounted() {
    store.watch(
      (state, getters) => getters["general/GET_LOADING"],
      (val) => {
        this.loading = val;
      }
    );
    await store.dispatch("general/initAPP").then((res) => {
      console.log("initAPP", res);
      this.initApp = true;
    });
    store.dispatch("general/initORMCORE").then((res) => {
      console.log("initORMCORE", res);
      if (res) {
        this.initORM = true;
      } else {
        this.error = "ORM not loaded, please try later";
      }
    });
    store.dispatch("general/initORMADDS").then((res) => {
      console.log("initORMADDS", res);
    });
  },
  methods: {
    calcloading() {
      return Math.floor((this.loading.from / this.loading.to) * 100);
    },
  },
});
