<template>
  <div id="map" class="map" :style="{ height: height + 'px' }">
    <div
      class="google-map"
      ref="googleMap"
      :style="{ height: height + 'px' }"
    ></div>
    <slot v-if="Boolean(google)" :google="google" :map="map" :bounds="bounds" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Loader } from "@googlemaps/js-api-loader";

export default defineComponent({
  name: "gMap",
  props: {
    mapConfig: Object,
    apiKey: String,
    height: { type: Number, default: 450 },
  },
  data() {
    return {
      google: null,
      map: null,
      bounds: null,
    };
  },
  mounted() {
    const googleMapApi = new Loader({
      apiKey: this.apiKey,
      version: "weekly",
    });
    const mapContainer = this.$refs.googleMap;

    googleMapApi
      .load()
      .then(() => {
        this.google = window.google;
        this.map = new window.google.maps.Map(mapContainer, this.mapConfig);
        this.bounds = new window.google.maps.LatLngBounds();
      })
      .catch(() => {
        // do something
      });
  },
});
</script>
