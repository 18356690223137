const apiURL = process.env.VUE_APP_API_ENDPOINT; //"/api";

import withQuery from "with-query";

export default {
  async get(route, payload, cb) {
    // console.log(route);
    // console.log(cb);
    // console.log("payload", payload);

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "cache-control": "no-cache",
      },
    };
    let status;

    await fetch(withQuery(apiURL + route, payload), requestOptions)
      .then((response) => {
        status = response.status;
        return response.json();
      })
      // .then((response) => response.json())
      .then(
        (json) => {
          const data = json;
          //   console.log(levels);
          cb(data);
          //   return levels;
        },
        () => {
          // #  console.log(error.status);
          // throw status;
          // console.log("Error loading json:", JSON.parse(JSON.stringify(error)));
          cb({ error: status });
        }
      )
      .catch((error) => cb("error", error));
  },

  async post(route, payload, cb) {
    // POST request using fetch with error handling
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: payload, //JSON.stringify({ title: 'Vue POST Request Example' })
    };
    let data = Object;
    await fetch(withQuery(apiURL + route, payload), requestOptions)
      .then((response) => response.json())
      .then((json) => {
        data = json;
        cb(data);
      })
      .catch((error) => {
        //this.errorMessage = error;
        console.error("There was an error!", error);
        cb(error);
        return error;
      });

    return data;
  },

  async postHeaders(route, payload, cb) {
    // POST request using fetch with error handling
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: payload, //JSON.stringify({ title: 'Vue POST Request Example' })
    };
    let data;
    let status;
    await fetch(withQuery(apiURL + route, payload), requestOptions)
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((json) => {
        cb({ status: status, data: json });
      })
      .catch((error) => {
        //this.errorMessage = error;
        console.error("There was an error!", error);
        cb(error);
        return error;
      });

    return { status: status, data: data };
  },
};
