<template>
  <Header />
  <reservationinfobar step="success" />
  <div class="orm-background--grey orm-py-16">
    <div class="orm-container">
      <div class="orm-background--white orm-py-30 orm-px-30">
        <div class="orm-row">
          <div class="orm-col-xs-12 orm-mb-16">
            <h1 class="orm-headline__h2--alt">
              {{ i18n('success/headline') }}
            </h1>
            <h2 class="orm-headline__h4" v-html="i18n('success/text')">
              
            </h2>
          </div>
        </div>
        <div class="orm-row">
          <div class="orm-col-xs-12 orm-col-md-6">
            <div class="orm-row" v-if="data.startstation">
              <div class="orm-col-xs-12 orm-col-sm-6 orm-mb-16">
                <div
                  class="orm-borderRadius__small orm-reservationFinal__datebox orm-py-16 orm-px-16"
                >
                  <div class="orm-mb-16"><strong>{{ i18n('general/pickup') }}</strong></div>
                  <div class="orm-reservationFinal__flex orm-mb-8">
                    <span class="orm-pr-16"
                      ><i class="fas fa-map-marker-alt"></i
                    ></span>
                    <div>
                      <div>
                        <strong> {{ data.startstation.name }}</strong>
                      </div>
                      <small> {{ data.startstation.street }}</small>
                    </div>
                  </div>
                  <div class="orm-reservationFinal__flex orm-mb-8">
                    <span class="orm-pr-16"
                      ><i class="fal fa-calendar-alt"></i
                    ></span>
                    <div>
                      <strong> {{ formateDateShort(data.startdate) }}</strong>
                    </div>
                  </div>
                  <div class="orm-reservationFinal__flex">
                    <span class="orm-pr-16"><i class="fal fa-clock"></i></span>
                    <div>
                      <strong>{{ formatTime(data.startdate) }} {{ i18n('general/hour') }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="orm-col-xs-12 orm-col-sm-6 orm-mb-16">
                <div
                  class="orm-borderRadius__small orm-reservationFinal__datebox orm-py-16 orm-px-16"
                >
                  <div class="orm-mb-16"><strong>{{ i18n('general/delivery') }}</strong></div>
                  <div class="orm-reservationFinal__flex orm-mb-8">
                    <span class="orm-pr-16"
                      ><i class="fas fa-map-marker-alt"></i
                    ></span>
                    <div>
                      <div>
                        <strong> {{ data.planned_endstation.name }}</strong>
                      </div>
                      <small> {{ data.planned_endstation.street }}</small>
                    </div>
                  </div>
                  <div class="orm-reservationFinal__flex orm-mb-8">
                    <span class="orm-pr-16"
                      ><i class="fal fa-calendar-alt"></i
                    ></span>
                    <div>
                      <strong>
                        {{ formateDateShort(data.planned_enddate) }}</strong
                      >
                    </div>
                  </div>
                  <div class="orm-reservationFinal__flex">
                    <span class="orm-pr-16"><i class="fal fa-clock"></i></span>
                    <div>
                      <strong> {{ formatTime(data.planned_enddate) }} {{ i18n('general/hour') }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="orm-col-xs-12 orm-col-md-6 orm-mb-64 orm-pl-md-30">
            <div class="borderRadiusTBRL overflowHidden">
              <gMap
                :mapConfig="mapConfig"
                :apiKey="apiKey"
                :height="250"
                v-if="data.startstation"
                v-slot="{ google, map, bounds }"
              >
                <gMapMarker
                  v-if="data.startstation != 0"
                  :marker="data.startstation"
                  :google="google"
                  :map="map"
                  :bounds="bounds"
                  :key="data.startstation"
                  :noselect="true"
                />
              </gMap>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapSettings } from "@/constants/mapSettings";

import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import Header from "@/components/widgets/Header.vue"; // @ is an alias to /src
import store from "@/store";
import reservationinfobar from "@/components/reservation/infobar_reservation.vue"; // @ is an alias to /src
import gMap from "@/components/maps/gMap.vue";
import gMapMarker from "@/components/maps/gMapMarker.vue";
import { formateDateShort, formatTime } from "@/constants/formater";

export default defineComponent({
  name: "Reservation/Final",
  components: {
    Footer,
    Header,
    reservationinfobar,
    gMap,
    gMapMarker,
  },
  setup() {
    const mapConfig = {
      ...mapSettings,
      center: { lat: 52.29240999999999, lng: 8.920219999999972 },
    };
    return {
      mapConfig,
      formateDateShort,
      formatTime,
    };
  },
  data() {
    return {
      data: Object,
      session: Object,
      startstation: Object,
      endstation: Object,
      apiKey: process.env.VUE_APP_MAPS_API_KEY,
      bounds: [],
    };
  },

  async mounted() {
    this.data = await store.getters["reservation/GET_RESERVATION_SUCCESS"];
    store.dispatch("reservation/DELETE_TARIF");

    // console.log(this.data);

    if (this.data == null) {
      this.$router.push({ name: 'Home'});
    }
    // console.log(store.getters["reservation/GET_RESERVATION"]);
    // this.data = this.$route.params.data;
  },
});
</script>
