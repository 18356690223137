<template>
  <div class="orm-select" :class="{ 'orm-disabled': isDisabled == true }">
    <label class="orm-form__label" :for="myId"
      >{{ myLabel }}<span v-if="isRequired">*</span></label
    >
    <div class="orm-select__control" :class="{ 'orm-form__error': error }">
      <select
        :id="myId"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :required="isRequired"
        class="selectColor"
        :disabled="isDisabled"
      >
        <option v-if="myPlaceholder != ''" disabled value="">{{
          myPlaceholder
        }}</option>
        <option
          v-for="myOption in myOptions"
          :key="myOption"
          :value="myOption[myOptionsValue]"
          >{{ myOption[myOptionsText] }}</option
        >
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    myId: {
      type: String,
      required: true,
    },
    myLabel: {
      type: String,
      required: true,
    },
    myPlaceholder: {
      type: String,
      required: false,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    myOptions: {
      required: true,
    },
    myOptionsValue: {
      required: true,
    },
    myOptionsText: {
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
};
</script>
