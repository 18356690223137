
import { defineComponent } from "vue";
import store from "@/store";
import datepicker from "@/components/ui/datepicker.vue"; // @ is an alias to /src

import stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src

export default defineComponent({
  name: "reservationheader",
  components: { stationpicker, datepicker },
  setup() {
    const stations = store.getters["stations/GET_STATIONS"];

    return {
      stations,
      store,
    };
  },
  data() {
    return {
      stationSelected: store.getters["userSession/GET_STATION"],
    };
  },
  props: {
    data: Object,
    type: String,
    step: Number,
  },
});
