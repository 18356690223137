<template>
  <label class="orm-radio" :class="{ 'orm-multisize': myMultisize == true }">
    <span class="orm-radio__input">
      <input
        type="radio"
        :name="myName"
        :checked="modelValue"
        @input="$emit('update:modelValue', $event.target.checked)"
      />
      <span class="orm-radio__control"></span>
    </span>
    <span class="orm-radio__label"
      ><b>{{ myLabel }} </b><br v-if="myMultiline" />{{ mySmall }}</span
    >
  </label>
</template>
<script>
export default {
  props: {
    myMultisize: {
      type: Boolean,
      required: false,
      default: false,
    },
    myMultiline: {
      type: Boolean,
      required: false,
      default: false,
    },
    myName: {
      required: true,
    },
    myLabel: {
      type: String,
      required: true,
    },
    mySmall: {
      type: String,
      required: false,
    },
    modelValue: {
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
};
</script>
